import { getOrderStudentpage, getSchoolActiveInfo, buyAllInsure, refundInsure, showInsurePolicy, createStudentReport } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        order_no: '',
        policy_no: '',
        check_state: '',
        is_buy_insure: '',
        operator_server_active_id: '',
        current: 1,
        total: 0
      },
      schoolActive: [],
      exportIds: '',
      checkMuneSer: [],
      fileUrl: '../../static/file/course.xlsx'
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.id) {
      this.pageInfo.operator_server_active_id = data.id;
      this.getList();
    }
  },
  methods: {
    reset() {
      this.pageInfo.order_no = '';
      this.pageInfo.is_buy_insure = '';
      this.pageInfo.check_state = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      getOrderStudentpage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    getSchoolActiveInfo() {
      getSchoolActiveInfo({
        id: this.pageInfo.school_active_id
      }).then(res => {
        this.schoolActive = res.data;
      });
    },
    createStudentReport(studentId) {
      createStudentReport({
        student_id: studentId,
        operator_server_active_id: this.pageInfo.operator_server_active_id
      }).then(res => {
        if (res.success && res.data) {
          location.href = this.$root.projectName + '/' + res.data;
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    },
    checkAllOrder() {
      let that = this;
      this.$root.ElMessageBox.confirm('你确定要为参与该活动的学生购买保险么？该操作可能会花费很长时间请耐心等待。', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
        draggable: true
      }).then(() => {
        buyAllInsure({
          operator_server_active_id: this.pageInfo.operator_server_active_id
        }).then(res => {
          that.$root.ElMessageBox.alert(res.msg);
          if (res.success) {
            that.search();
          }
        });
      });
    },
    showPolicy(policy_no) {
      //查看保单
      showInsurePolicy({
        policy_no: policy_no
      }).then(res => {
        if (res.success && res.data) {
          //window.open('data:application/pdf;base64,'+res.data);
          this.openPdf(res.data);
        } else {
          this.$root.ElMessageBox.alert(res.msg);
        }
      });
    },
    openPdf(pdfBase) {
      var pdfWindow = window.open("", "_blank");
      pdfWindow.document.open("application/pdf");
      pdfWindow.document.write("<iframe src='data:application/pdf;base64," + pdfBase + "' frameborder='0' style='border:0; padding:0; margin:0; top:0; left:0; width:100%; height:100%;' allowfullscreen></iframe>");
      pdfWindow.document.close();
    },
    refundInsureData(policy_no) {
      let that = this;
      this.$root.ElMessageBox.confirm('你确定要退保吗？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
        draggable: true
      }).then(() => {
        refundInsure({
          policy_no: policy_no
        }).then(res => {
          that.$root.msgResult(res, '', that, 'search');
        });
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    }
  }
};